import PropTypes from 'prop-types'
import React from 'react'
import LogoTall from "../images/logo_tall.png"

const Header = (props) => {
  const langLookup = {"en": ["Intro", "Work", "About Mounte AB", "Contact"],
    "sv": ["Intro", "Arbeten", "Om Mounte AB", "Kontakt"]
  }
  const langData = langLookup[props.lang] || langLookup["sv"]
  

  return <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img src={LogoTall} alt="Mounte" className="logo-img"></img>
    </div>
    <div className="content">
      <div className="inner">
        {/*
        <h1>{props.lang === "sv" ? "Data Science | Digitalisering" : "Welcome" }</h1>
        
        */}
        {props.lang === "sv" &&
        <p>
          Mounte AB erbjuder konsulttjänster inom <b>digitalisering</b>, <br />
          där <b>Data Science</b> och <b>mjukvaruutveckling</b> är några av spetskompetenserna. <br />
          Vad har ni för utmaningar och problem? Söker ni en effektivare lösning?< br/>
          Låt oss få vara en del av lösningen.
          { /*
          <b><a href="#contact" onClick={() => props.onOpenArticle('contact')}>Klicka här</a></b> för att kontakta oss.
          */
          }  
        </p>
        }
        {props.lang === "en" &&
        <p>
          Consulting services related to data science, digitalisation, software development and architecture.
          When your organization need to fill gaps or get a giid injection of productive work.
          When you find the need to explore new oportunities and make a change.
        </p>
        }
      </div>
    </div>
    <nav>
      <ul>
        {
          /*
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            {langData[0]}
          </button>
        </li>
        */
        }
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            {langData[2]}
          </button>
        </li>
        {
          /*
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('work')
              }}
            >
              {langData[1]}
            </button>
          </li>
          
          */
        }
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            {langData[3]}
          </button>
        </li>
      </ul>
    </nav>
  </header>
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
