import PropTypes from 'prop-types'
import React from 'react'
//import pic01 from '../images/pic01.jpg'
//import pic02 from '../images/pic02.jpg'
import about_img from '../images/about_img.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        aria-hidden="true"
      ></div>
    )

    const lang = this.props.lang;

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        {
          /*
          <article
            id="intro"
            className={`${this.props.article === 'intro' ? 'active' : ''} ${
              this.props.articleTimeout ? 'timeout' : ''
            }`}
            style={{ display: 'none' }}
          >
            <h2 className="major">Intro</h2>
            <span className="image main">
              <img src={pic01} alt="" />
            </span>
            <p>
  
            </p>
            <p>
              
            </p>
            {close}
          </article>
          
          */
        }

{
/*
<article
  id="work"
  className={`${this.props.article === 'work' ? 'active' : ''} ${
    this.props.articleTimeout ? 'timeout' : ''
  }`}
  style={{ display: 'none' }}
>
  <h2 className="major">Arbeten</h2>
  <span className="image main">
    <img src={pic02} alt="" />
  </span>
  <p>
    För 
  </p>
  <p>
    Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
    libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
    Pellentesque condimentum sem. In efficitur ligula tate urna.
    Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor.
    Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
    libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat
    tempus.
  </p>
  {close}
</article>

*/
}

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Om Mounte AB</h2>
          <span className="image main">
            <img src={about_img} alt="" />
          </span>
          <p>
            Mounte AB grundades under 2021 av Daniel Wedlund med en enkel vision - <i>"att skapa effektiva lösningar inom digitalisering och göra de tillgängliga för slutanvändaren"</i>. <br/>
            Från den mångåriga erfarenheten inom olika organisationer, från små startups till storföretag, identifierades likvärdiga mönster av grundläggande behov för effektiva lösningar inom digitalisering. <br/>
            Digitalisering är ett brett område som spänner över många kompetensområden där Mounte AB bland annat har spetskompetens inom
            
            <ul>
              <li>Data Science, Maskininlärning, AI</li>
              <li>Algoritmutveckling, matematisk modellering och optimeringsproblem</li>
              <li>Bildanalys och datorseende</li>
              <li>Mjukvaruarkitektur</li>
              <li>Mjukvaruutveckling: backend, front-end och embedded</li>
              <li>Molnmiljöer</li>
            </ul>
            </p>
            <p>
              Utöver dessa kompetenser finns erfarenhet, inom data science och digitalisering; att driva och leda projekt, rekrytera och bygga team/organisationer.
              Behöver ni stöd med frågor av denna karaktär, tveka inte att kontakta oss.
            </p>
              <p>
              Vi har en ödmjuk inställning till den expert- och domänkunskap ni som potentiell kund besitter och ser som vårt uppdrag att fånga och dra nytta av den.
              Något vi lärt oss är att innovativ teknik i sig själv sällan löser kundproblemen. Det krävs en förankring i verksamheten och verkligheten för att tekniken ska komma till full nytta.
            </p>
            <p>
              Mounte AB har sitt säte i Sverige men har hela världen som arbetsplats.
              </p>
            
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>
          <p>Ingen fråga är för liten (eller stor), tveka inte att fylla i och skicka in formuläret nedan:</p>
          <form method="post" action="https://formspree.io/f/mqkwawev">
            <div className="field half first">
              <label htmlFor="name">Namn</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="_replyto" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Meddelande</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Skicka Meddelandet" className="special" />
              </li>
              <li>
                <input type="reset" value="Rensa" />
              </li>
            </ul>
          </form>
          <p>Vår mailadress är: <a href="mailto:kontakt@mounte.se">kontakt@mounte.se</a></p>.
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/memounte"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/mounte" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/mounte"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
